<template>
    <div>
        <div v-if="!editmode">
            <div class="si-selectedfilter-title-row">
                <div class="si-selectedfilter-title">{{viewdata.name}}</div>
                <div class="si-selectedfilter-edit_btn">
                    <el-button type="text" @click="editmode_event"><svg-icon icon-class="edit" /></el-button>
                </div>
            </div>
            <div class="si-selectedfilter-description">
                {{viewdata.description}}
            </div>
        </div>
        <div v-else class="si-selectedfilter-edit">
            <el-input 
                size="small" 
                ref="NameInput"
                v-model="formdata.name"
                v-on:keyup.enter="save_event"
                v-on:keyup.native.enter="save_event"
                placeholder="Filter Name" />

            <el-input 
                size="small" 
                ref="DescrInput"
                v-model="formdata.description"
                v-on:keyup.enter="save_event"
                v-on:keyup.native.enter="save_event"
                placeholder="Filter Description" />
        </div>
    </div>
</template>

<script>

export default {
    props: ['value'],

    data: function () {
        return {
            viewdata : this.value,
            editmode : false,
            formdata : {},
        }
    },

    methods: {
        editmode_event(){
            this.editmode = true;
            this.formdata = Object.assign({}, JSON.parse(JSON.stringify(this.viewdata)));
            this.$nextTick(() => {
                this.$refs.NameInput.focus();
            });
        },

        save_event(){
            if (this.viewdata.name !== this.formdata.name || this.viewdata.description !== this.formdata.description) {
                this.$store.dispatch('userFilters/updateItem', this.formdata).then(() => {
                    this.$emit('change');
                    this.editmode = false;
                });
            }
            else {
                this.editmode = false;
            }
        },
    },

    watch: {
        value(){
            this.viewdata = this.value
            this.editmode = false
        }
    }
}
</script>
